import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import ReactFullPage from "@fullpage/react-fullpage"
import ArrowD from '../images/arrow.svg'

const sectionStyle = {
  textAlign: "center",
  fontFamily: "LacrimaMG-Senza",
  fontSize: "1.6vw",
  lineHeight: "2.4vw"
}

const ArrowDownStep = () => {
  if(typeof window !== 'undefined') {
    window.changeArrow = () => {
      if(window.fullpageApi.getActiveSection().index == 7) {
        document.querySelector('.offreArrow').classList.remove('arrowDown');
        document.querySelector('.offreArrow').classList.add('arrowUp');
      } else {
        document.querySelector('.offreArrow').classList.add('arrowDown');
        document.querySelector('.offreArrow').classList.remove('arrowUp');
      }
    }

    const moveTo = () => {
      if(document.querySelector('.offreArrow').classList.contains('arrowDown')) {
        window.fullpageApi.moveSectionDown()
      } else {
        window.fullpageApi.moveTo(1,0);
      }
    }
    
    return (<div onClick={() => moveTo()} className="offreArrow arrowDown"><ArrowD/></div>)
  } else {
    return (<></>)
  }
}


const Fullpage = () => (

  <ReactFullPage
    //fullpage options
    licenseKey = {'1AFAA54A-E416442F-8F526529-A2EF51E1'}
    scrollingSpeed = {1200}
    onLeave={() => window.changeArrow()}
    scrollBar = {true}
    render={({ state, fullpageApi }) => {
      if(typeof window !== 'undefined') {
        window.fullpageApi = fullpageApi
      }
      
      return (
        <div className="offre">
          <ReactFullPage.Wrapper>
            <div className="section" style={sectionStyle}>                       
              <p className="mousedown bolder">Nous <b>œuvrons quotidiennement</b> et auprès d'une <b>large clientèle</b> à <b>faire en sorte que</b> :<br/><br/> <b>vous-même</b> ou vos équipes soient <b>conseillées finement,
              vos contrats</b> soient <b>négociés conformément à vos intérêts</b>, vos opérations <b>auditées</b>, vos <b>contentieux</b> vous <b>fassent progresser</b></p>
              <div><h2><button onClick={() => fullpageApi.moveSectionDown()}>DÉCOUVREZ 4 OFFRES DU CABINET <span></span></button></h2></div>
            </div>
            
          
            <div className="section" style={sectionStyle}>
              <div className="menu">
                <p><button onClick={() => fullpageApi.moveTo(3,0)} className="bolder"> <b>L’ENTRE</b>PRENA<b>RIAT</b> <span></span></button></p>
                <p><button onClick={() => fullpageApi.moveTo(4,0)} className="bolder"><b>PATRI</b>MOINE & <b>FISC</b>ALITÉ <span></span></button></p>
                <p><button onClick={() => fullpageApi.moveTo(8,0)} className="bolder"><b>SI / DIGITAL / DATA</b> <span></span></button></p>
                <p><button onClick={() => fullpageApi.moveTo(9,0)} className="bolder"><b>PRO</b>PRIÉTÉ <b>INTEL</b>LECTUELLE <span></span></button></p>
              </div>
            </div>

            <div className="section" style={sectionStyle}>
              <h3>L’ENTREPRENARIAT</h3>
              <p className="bolder"><b>PCX</b> Avocats <b>accompagne</b> votre <b>projet</b> de sa <b>nais</b>sance 
              jusqu'<b>à</b>, malheureusement <b>parfois</b>, sa mort. Nous vous <b>aidons</b> à
              prendre les <b>meilleures</b> décisions en fonction
              des <b>objectifs</b> que vous nous <b>adressez</b>.
              Et pour vous <b>permettre</b> de ne vous <b>concentrer</b> que sur votre <b>modèle économique</b> et vos clients,
              nous vous fournissons une <b>offre complète</b> et <b>connue</b> à l'avance.</p>
              
              <p className="bolder">Une <b>question </b>? Un <b>doute </b>? Une <b>envie </b>?
              <b className="bolder">Interrog</b>ez nous afin d'en <b>savoir</b> davantage !
              <br/><button className="bolder" onClick={() => {window.location.href = 'mailto:pcocheteux@pcxavocats.fr'}}><b>pcocheteux</b>@pcxavocats.fr <span></span></button></p>
            </div>

            <div className="section bolder" style={sectionStyle}>
              <h3>PATRIMOINE & FISCALITÉ</h3>
              <p>Vous <b>possédez</b> un ch<b>â</b>teau classé
              monument <b>historique</b> ? Vous êtes professionnel libéral
              et vous souhaitez <b>diversifier vos revenus</b> tout
              en maintenant un <b>niveau d'IR raisonnable</b> ?
              Vous souhaitez <b>interroger</b> l'administration <b>fiscale</b> ?
              Nous répondrons <b>présents</b>.</p>

              <p>En matière <b>fiscale, PCX AVOCATS</b> assiste
              ses clients aussi bien en cas de procédure
              de <b>contrôle fiscal</b> et de ses suites 1
              qu’en vue de <b>diminuer</b> le coût fiscal de l’<b>impôt attaché</b>
              au dirigeant ou à l’entreprise <sup><b>2</b></sup>.</p>
            </div>

            <div className="section" style={sectionStyle}>
              <h3>1. DÉFENSE & RECOURS</h3>
              <p className="bolder"><b>Assistance</b> pendant le <b>contrôle fiscal</b> (vérification fiscale d’une entreprise et <b>examen</b>
              de la <b>situation fiscale personnelle</b> du dirigeant);
              <b> Recours hiérarchique</b> ainsi que devant
              les commissions <b>administratives fiscales</b>;
              Elaboration du <b>contentieux de l’impôt</b>: 
               <b> réclamation</b> devant la <b>DGFiP</b>, suivi devant le <b>TA</b> ou le <b>TGI</b>; <b>Assistance</b> en matière <b>pénale</b> et défense;
              <b> Médiation</b>: discussion de plan de règlement <b>échelonné</b> de paiement, garanties; <b>Négociation</b> de transaction;
              <b> Appui</b> en matière de mesures
              conservatoires ou <b>d’exécution</b>.</p>
            </div>

            <div className="section" style={sectionStyle}>
              <h3>2. OPTIMISATION FISCALE & CONSEIL PRÉVENTIF</h3>
              <p className="bolder">Recherche des <b>montages</b> les plus <b>intéressants</b> pour que l’entreprise <b>paie moins d’impôt</b>;
              <b> Optimisation</b> du <b>coût</b> d'une voiture de fonction;
              Comment <b>optimiser la TVA</b> ?
              Comment <b>maximaliser</b> la <b>rémunération</b> du dirigeant ?
              Quels <b>crédits d'impôt</b> pour le dirigeant et l’entreprise ?
              Comment se constituer un <b>patrimoine immobilier</b> tout en <b>réduisant ses impôts</b> ? <b>Aide</b> à la rédaction des <b>déclarations</b>.</p>
            </div>

            <div className="section bolder" style={sectionStyle}>
              <p>Toutes les <b>personnes physiques et entreprises</b>
              ont des <b>interro</b>gations sur leur <b>situation fiscale:</b></p>

              <p><b>respect</b> de leurs obligations et <b>choix</b> des structures <b>juridiques</b>
              les plus <b>adapt</b>ées à la situation <b>patri</b>moniale et <b>famili</b>ale.</p>

              <p><b>PCX</b> Avocats vous <b>conseil</b>le et vous <b>assiste</b> en <b>collabo</b>ration avec vos <b>conseil</b>lers habituels,
              établissements <b>financi</b>ers, <b>experts</b> comptables, <b>notaires</b>.</p>

              <p>Une <b>question </b>? Un <b>doute </b>? Une <b>envie </b>? Interrogez nous afin d'en <b>savoir</b> davantage !<br/>
              <button onClick={() => {window.location.href = 'mailto:pcocheteux@pcxavocats.fr'}}><b>pcocheteux</b>@pcxavocats.fr <span></span></button></p>
            </div>

            <div className="section bolder" style={sectionStyle}>
              <h3>SI / DIGITAL / DATA</h3>
              <p>Les systèmes d'<b>info</b>rmation s'alimentent
              de <b>don</b>nées et sont organisés grâce à <b>dif</b>férentes <b>techno</b>logies.
              L'<b>humain</b> fera toujours la <b>dif</b>férence, mais cela
              ne doit pas vous empêcher de vous <b>protéger</b>
              et de <b>renver</b>ser le rapport de <b>force </b>:</p>

              <p>contrats <b>IT</b>, compliance <b>RGPD, strat</b>égie de <b>négo</b>ciations
              au <b>bénéf</b>ice de votre <b>c</b>hiffre d'<b>a</b>ffaires.</p>

              <p>Une <b>question </b>? Un <b>doute </b>? Une <b>envie </b>? <b>Interro</b>gez nous afin d'en <b>savoir</b> davantage !<br/>
              <button onClick={() => {window.location.href = 'mailto:pcocheteux@pcxavocats.fr'}}><b>pcocheteux</b>@pcxavocats.fr <span></span></button></p>
            </div>

            <div className="section bolder" style={sectionStyle}>
              <h3>PROPRIÉTÉ INTELLECTUELLE</h3>
              <p><b>Stratégie</b> de Marques et <b>noms</b> de domaine :
              re<b>cherche</b> d'<b>antério</b>rité, <b>dépôt, prote</b>ction.</p>

              <p>Une <b>question</b> ? Un <b>doute</b> ? Une <b>envie</b> ?
              <b>Interro</b>gez nous afin d'en <b>savoir</b> davantage !<br/>
              <button onClick={() => {window.location.href = 'mailto:pcocheteux@pcxavocats.fr'}}><b>pcocheteux</b>@pcxavocats.fr <span></span></button></p>
            </div>
          </ReactFullPage.Wrapper>          
        </div>             
      );
    }}
  />
);

const OffrePage = () => {
  setTimeout(() => {
    
    document
    .querySelector("#menu")
    .querySelectorAll("li").forEach(li => {
      li.classList.remove('current')
    })
    
    document
    .querySelector("#menu")
    .querySelectorAll("li")[1]
    .classList.add("current");
  }, 2000)

  return(
    <Layout>
      <SEO title="Offre" />
      <ArrowDownStep></ArrowDownStep>    
      <Fullpage />    
    </Layout>
  )
}

export default OffrePage
